import React from "react"
import { Link, graphql } from "gatsby"

import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"
import PageTitle from "src/components/page-title"

import * as styles from "./blog-post-archive.module.css"


const BlogIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath },
}) => {

  const categories = data.allWpCategory.nodes
  const posts = data.allWpPost.nodes

  return <>
    <SEO
      title={"The Nickelled Blog"}
      description={
        "Posts on SaaS Business, User Onboarding, Training and more."
      }
    />
    <Header></Header>
    <main class="relative">
      <PageTitle
        preheader="Blog"
        title="The Nickelled Blog"
        subtitle="Our posts on customer success, user onboarding and more."
      ></PageTitle>
      <div class="relative py-16 bg-white overflow-hidden max-w-7xl mx-auto px-4">
        <ul class="w-full border-gray-300 flex flex-row flex-wrap md:flex-nowrap pb-16">
          <li class="border-gray-300 border-b md:border-l p-4 w-full font-bold text-gray-900 text-center md:text-left">
            Top Topics:
          </li>
          {categories.map(category => {
            return (
              <Link class="w-full text-center" to={"/blog/" + category.slug}>
                <li class="border-gray-300 border-b hover:bg-gray-100 p-4 cursor-pointer text-oOrange whitespace-nowrap">
                  {category.name}
                </li>
              </Link>
            )
          })}
        </ul>
        <div class="">
          <ul class="grid grid-cols-1 md:grid-cols-3 gap-x-12 gap-y-12">
            {posts.map(post => {
              return (
                <li>
                  <a href={"/blog" + post.uri}>
                    <div className={"aspect-w-16 aspect-h-9"}>
                      {post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData.src && (
                        <img
                          src={
                            post.featuredImage?.node?.localFile
                              ?.childImageSharp?.gatsbyImageData.src
                          }
                        />
                      )}
                      {!post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData.src && (
                        <img
                          src={post.featuredImage?.node?.localFile?.publicURL}
                          alt={``}
                        />
                      )}
                    </div>
                    <h3>{post.title}</h3>
                    <div
                      dangerouslySetInnerHTML={{ __html: post.excerpt }}
                      className={styles.PostExcerpt}
                    />
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
        <div class="flex-row flex justify-between">
          {previousPagePath && (
            <>
              <Link
                to={"/blog" + previousPagePath}
                class="text-oOrange font-bold text-sm"
              >
                Previous page
              </Link>
            </>
          )}
          {nextPagePath && (
            <Link
              to={"/blog" + nextPagePath}
              class="text-oOrange font-bold text-sm"
            >
              Next page
            </Link>
          )}
        </div>
      </div>
    </main>
    <Footer></Footer>
  </>;
}

export default BlogIndex

export const pageQuery = graphql`query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
  allWpCategory(sort: {fields: name}, filter: {name: {ne: "Uncategorized"}}) {
    nodes {
      name
      slug
    }
  }
  allWpPost(
    sort: {fields: [date], order: DESC}
    limit: $postsPerPage
    skip: $offset
  ) {
    nodes {
      excerpt
      uri
      date(formatString: "MMMM DD, YYYY")
      title
      excerpt
      featuredImage {
        node {
          sourceUrl
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}
`
